import pqImageDMPath from './images/P+Q_dm.png';
import pqImageLMPath from './images/P+Q_lm.png';

/**
 * In order for the static site generator to properly process
 * images for dynamic content each images must be explicity
 * imported and exported here.
 *
 * Do not create a component with logic here. Even though it is
 * allowed it will cause problems when the MDX is compiled.
 *
 * Expect to restart the dev server for changes here to take effect.
 * A yarn clean my be necessary in some cases as well.
 */

/* Both paths for DM to disable replacement logic for now. */
export const PQ_Image_DM_Path = pqImageDMPath;
export const PQ_Image_LM_Path = pqImageLMPath;
